import { css } from 'styled-components';
export default css`
  /* inspiration:
  https://github.com/michaeljdeeb/prism-solarized-dark/blob/master/prism-solarizeddark.css */

  /* Base styles */
  code[class*='language-'],
  pre[class*='language-'] {
    font-family: Consolas, Menlo, Monaco, source-code-pro, 'Courier New',
      'Andale Mono', 'Ubuntu Mono', monospace;
    font-feature-settings: normal;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;

    line-height: 1.5;
    margin-bottom: 0;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  /* Code blocks */
  pre[class*='language-'] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    border-radius: 0.3em;
    color: #839496;
    background: #002b36;
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    border-radius: 0.4em;
    background: rgba(255, 229, 100, 0.2);
    color: #1a1a1a;
    padding: 0.4em 0.5em 0.3em;
    white-space: normal;
  }

  /* Text selection for block */
  pre[class*='language-'] span ::selection,
  pre[class*='language-'] code ::selection {
    text-shadow: none;
    background: hsla(0, 0%, 100%, 0.15);
  }

  /* Token colors */
  .token.attr-name,
  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #93a1a1;
  }

  .token.punctuation {
    color: #657b83;
  }

  .namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #d33682;
  }

  .token.attr-value,
  .token.selector,
  .token.string,
  .token.char,
  .token.builtin,
  .token.url,
  .token.inserted {
    color: #2aa198;
  }

  .token.entity {
    color: #2aa198;
  }

  .token.atrule,
  .token.keyword {
    color: #859900;
  }

  .token.tag,
  .token.function {
    color: #268bd2;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #cb4b16;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.attr-name,
  .token.selector,
  .token.doctype,
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  /* String numbers and highlight */
  pre[data-line] {
    padding: 1em 0 1em 3em;
    position: relative;
  }

  .gatsby-highlight-code-line {
    background-color: hsla(52, 90%, 30%, 0.25);
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 0.75em;
    border-left: 0.25em solid hsla(52, 90%, 60%, 1);
  }

  .gatsby-highlight {
    margin-bottom: 1.75rem;
    border-radius: 10px;
    background: #011627;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }

  .gatsby-highlight pre[class*='language-'] {
    float: left;
    min-width: 100%;
  }
`;
