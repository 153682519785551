import { createGlobalStyle } from 'styled-components';
import { rhythm, scale } from './typography';
import codeHighlight from './code';

export default createGlobalStyle`
  a {
    color: hsla(110, 54%, 30%, 1);
  }

  p a:not(.gatsby-resp-image-link) {
    position: relative;
    text-decoration: none;
    box-shadow: none;
    display: inline-block;
    color: inherit;
    font-style: italic;
    white-space: nowrap;
    padding-right: 4px;
    padding-left: 3px;
    margin-right: -3px;
    margin-left: -2px;
    
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      border-radius: 1px;
      background-color: hsla(110, 54%, 35%, 0.9);
      transform: scaleY(0.05);
      transform-origin: bottom left;
      transition: all cubic-bezier(0.65, 0.05, 0.36, 1) 0.15s;
    }
    
    &:hover {
      &::after {
        background-color: hsla(110, 54%, 60%, 0.50);
        transform: scaleY(1);
      }
    }
  }

  .gatsby-resp-image-wrapper {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 70px;
  }

  figcaption {
    color: hsla(0, 0%, 0%, 0.8);
    text-align: center;
    font-size: 0.8rem;
  }

  hr:not([style]) {
    margin-top: ${rhythm(2)};
    margin-bottom: ${rhythm(2.5)};
    height: 0;
    text-align: center;

    &:before {
      content: '...';
      ${scale(1)};
      display: inline-block;
      color: hsla(0, 0%, 0%, 0.8);
      position: relative;
      top: -${rhythm(1.4)};
      font-weight: 400;
      margin-left: .6em;
      letter-spacing: .6em;
    }
  }

  ${codeHighlight}
  
`;
